import React, {useContext, useEffect, useState} from "react";
import {AzureAuthContext} from "../../../contexts/AzureAuthContext";
import {Button} from "../../form";
import {TableComponent} from "../../base/TableComponent";
import {APIContext} from "../../../contexts/APIContext";
import {ModalComponent} from "../../base/ModalComponent";
import {useFormik} from "formik";
import * as Yup from "yup";
import {FormComponent} from "../../dynamic/form";
import {FormApprovals} from "../../../forms";
import {useTranslation} from "react-i18next";
import {isNumber} from "lodash";
import SearchBoxUsers from "../../base/SearchBoxUsers";

export default function ApprovalsCatalog() {
    const {t} = useTranslation();
    const {
        getAllUsersApplication,
        createUsersApplication,
        getCountries
    } = useContext(APIContext);
    const [users, setUsers] = React.useState([]);
    const [countries, setCountries] = React.useState([]);
    const [openModal, setOpenModal] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        getAllCountries();
    }, []);


    const handleSubmitFilter = (criteria) => {
        const queryParams = Object.entries(criteria)
            .filter(([key, value]) => value)
            .map(([key, value]) => `${key}=${value}`)
            .join("&");

        getData(1, queryParams);
    };

    const getData = (page, queryParams) => {
        setLoading(true);
        queryParams = queryParams != null ? `&${queryParams}` : '';
        getAllUsersApplication(page, queryParams).then((data) => {

            const transformedData = {
                ...data,
                data: data.data.map((user) => ({
                    ...user,
                    status: user.status === 0 ? 'Inactive' : 'Active',
                    is_staff: (user.is_staff == null || user.is_staff === '') ? 'Not Defined' : (user.is_staff === 1 ? 'Yes' : 'No')
                })),
            };
            setUsers(transformedData);
        }).finally(() => setLoading(false));
    }

    const getAllCountries = () => {
        getCountries().then((data) => setCountries(data));
    }

    const newItem = () => {
        setOpenModal(true);
    }

    const editItem = (row) => {
        setOpenModal(true);
        formik.setFieldValue('id', row.id);
        formik.setFieldValue('description', row.description);
        formik.setFieldValue('email', row.email);
        formik.setFieldValue('status', row.status === 'Inactive' ? 0 : 1);
        formik.setFieldValue('country', row.country);
        formik.setFieldValue("user_type", row.user_type);
    }

    const {userProfile} = useContext(AzureAuthContext);

    const apiCall = async (id, type) => {
        const api = process.env.REACT_APP_API_BASE_URL;
        const endpoint = `${api}users-application-state/${id.toString()}`;
        try {
            const response = await fetch(endpoint, {
                method: type,
                headers: {
                    Authorization:
                        userProfile.userPrincipalName &&
                        `Basic ${Buffer.from(userProfile.userPrincipalName).toString(
                            "base64"
                        )}`,
                },
            });
            const data = await response.json();
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    }

    const deleteUser = async (id) => {
        await apiCall(id, 'DELETE')
    }
    const updateUser = async (payload, id) => {
        payload.status = Number(payload.status)
        const raw = JSON.stringify(payload);
        const api = process.env.REACT_APP_API_BASE_URL;
        const endpoint = `${api}users-application-state/${id.toString()}`;
        try {
            const response = await fetch(endpoint, {
                method: 'PUT',
                headers: {
                    Authorization:
                        userProfile.userPrincipalName &&
                        `Basic ${Buffer.from(userProfile.userPrincipalName).toString(
                            "base64"
                        )}`,
                    'Content-Type': 'application/json',
                },
                body: raw,
            });

            const contentType = response.headers.get('Content-Type');
            if (!contentType || !contentType.includes('application/json')) {
                throw new Error('Unexpected response format. Expected JSON.');
            }

            const data = await response.json();
            console.log(data);
        } catch (error) {
            console.error('Error updating user:', error.message);
        }
    };



    const deleteItem = async (id) => {
        let payload = {
            id: id
        }


        await deleteUser(payload.id).then((data) => {
            setUsers([]);
            getData();
            closeModal();
        }).catch((e) => {
            setErrorMessage("An error occurred while deleting the user");

            setTimeout(()=> {
                setErrorMessage(null);
            }, 4000)
        });
    }

    const closeModal = () => {
        setOpenModal(false);
        formik.resetForm();
    }

    const columns = [
        {label: "Id", keyValue: "id", minWidth: "80px"},
        {label: "Status", keyValue: "status"},
        {label: "Email", keyValue: "email"},
        {label: "Description", keyValue: "description"},
        {label: "Country", keyValue: "country"},
        {label: "User Type", keyValue: "user_type"},
        {label: "Clinic Name", keyValue: "clinic_name"},
        {label: "BP Code", keyValue: "bp_code"},
        {label: "Is Staff", keyValue: "is_staff"},
    ];

    const Schema = Yup.object({
        status: Yup.string().required(),
        description: Yup.string().required(),
        country: Yup.string().required(),
        email: Yup.string().required(),
        "user_type": Yup.string().required(),
    });

    const formik = useFormik({
        initialValues: {
            id: "",
            status: "",
            email: "",
            description: "",
            country: "",
            user_type: "",
        },
        validationSchema: Schema,
        onSubmit: (values) => {

            let payload = {
                email: values.email,
                country: values.country,
                user_type: values.user_type,
                status: values.status,
                description: values.description,
            }

            if (isNumber(values.id)) {
                updateUser
                    (payload, values.id).then((data) => {
                    setUsers([]);
                    getData();
                    closeModal();
                }).catch((e) => console.log(e));
            } else {
                createUsersApplication(payload).then((data) => {
                    setUsers([]);
                    getData();
                    closeModal();
                }).catch((e) => console.log(e));
            }
        },
    });

    const actions = {
        close: closeModal,
        delete: deleteItem
    }

    return (
        <>
            <Button onClick={() => newItem()} primary>{t("general.new")}</Button>
            <SearchBoxUsers onSearch={handleSubmitFilter} availableCriterias={[
                {key: "email", label: "Search By Email"},
                {key: "clinic_name", label: "Search By Clinic Name"},
                {key: "bp_code", label: "Search By BP Code"},
            ]} />
            {users && <TableComponent rows={users} columns={columns} editRow={editItem} fetchData={getData} autoLoad={true} isLoading={loading}/>}
            {openModal && <ModalComponent open={openModal} close={closeModal} errorMessage={errorMessage} title={`${formik.values.id === "" ? "New User to Approve" : "Edit User"}`}
                                          content={<FormComponent formFields={FormApprovals( countries, formik)} formik={formik} action={actions} />}/>}
        </>
    );
}
